import {FunctionComponent, useEffect, useState} from 'react';

import {EProfileTabs} from 'Core/Enums';
import {observer} from 'mobx-react';

import {useAuth} from '../../../Services/Auth';
import HeaderProfile from '../HeaderProfile';
import PageWrapper from '../PageWrapper';
import ProfileContent from '../ProfileContent';

const Profile: FunctionComponent = () => {
    const {getUser} = useAuth();
    const [activeTab, setActiveTab] = useState<EProfileTabs>(EProfileTabs.settings);

    useEffect(() => {
        void getUser();
    }, []);

    return (
        <PageWrapper header={<HeaderProfile activeTab={activeTab} setActiveTab={setActiveTab} />}>
            <ProfileContent activeTab={activeTab} />
        </PageWrapper>
    );
};

export default observer(Profile);
