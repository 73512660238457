import {FunctionComponent, useEffect, useState} from 'react';

import {LockOutlined, MailOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input, Layout, Typography} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {EPageTitle} from '../../../Core/Enums';
import {useAuth} from '../../../Services/Auth';
import useStores from '../../../Stores';

const RegistrationForm: FunctionComponent = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {registration} = useAuth();
    const [emailValue, setEmailValue] = useState('');
    const [loginValue, setLoginValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [passwordConfirmValue, setPasswordConfirmValue] = useState('');
    const {setEmail} = useStores().profileStore;

    useEffect(() => {
        document.title = EPageTitle.REGISTRATION;
    }, []);

    const validateMessages = {
        required: 'Поле не может быть пустым',
        types: {
            email: 'Некорректный email',
        },
    };
    const isDisabled = checkIsFormDisabled();

    async function handleOnClickSubmit(): Promise<void> {
        void form.validateFields().then(() => {
            const payload = {
                email: emailValue,
                login: loginValue,
                password: passwordValue,
                confirmed_password: passwordConfirmValue,
            };
            setEmail(emailValue);
            navigate(ROUTES.REGISTRATION_CONFIRM.PATH);
            void registration(payload);
            // navigate(ROUTES.REGISTRATION_CONFIRM.PATH);
        });
    }
    function checkIsFormDisabled(): boolean {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return !(emailRegex.test(emailValue) && passwordValue.length > 0 && passwordValue === passwordConfirmValue);
    }

    return (
        <Layout className={s.authPage}>
            <Content className={s.authContent}>
                <Typography.Title className={s.title}>AI Rabbit</Typography.Title>
                <Form
                    className={s.authForm}
                    form={form}
                    layout="vertical"
                    name="nest-messages"
                    validateMessages={validateMessages}
                >
                    <Form.Item className={s.formItem} name="email" rules={[{type: 'email'}, {required: true}]}>
                        <Input
                            className={s.antInput}
                            prefix={<MailOutlined className={s.icon} />}
                            placeholder="ПОЧТА"
                            value={emailValue}
                            onChange={(e) => setEmailValue(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item className={s.formItem} name="login" rules={[{required: true}]}>
                        <Input
                            className={s.antInput}
                            prefix={<UserOutlined className={s.icon} />}
                            placeholder="ЛОГИН"
                            value={loginValue}
                            onChange={(e) => setLoginValue(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item className={s.formItem} name="password" rules={[{required: true}]}>
                        <Input.Password
                            className={s.antInput}
                            prefix={<LockOutlined className={s.icon} />}
                            placeholder="ПАРОЛЬ"
                            value={passwordValue}
                            onChange={(e) => setPasswordValue(e.target.value)}
                        />
                    </Form.Item>
                    {passwordValue ? (
                        <Form.Item className={s.formItem} name="password-confirm" rules={[{required: true}]}>
                            <Input.Password
                                className={s.antInput}
                                prefix={<LockOutlined className={s.icon} />}
                                placeholder="ПОВТОРИТЕ ПАРОЛЬ"
                                value={passwordConfirmValue}
                                onChange={(e) => setPasswordConfirmValue(e.target.value)}
                            />
                        </Form.Item>
                    ) : null}
                    <Form.Item className={s.formItem}>
                        <Button
                            className={s.createButton}
                            type="primary"
                            htmlType="submit"
                            disabled={isDisabled}
                            onClick={handleOnClickSubmit}
                        >
                            Зарегистрироваться
                        </Button>
                    </Form.Item>
                    <Form.Item className={s.formItem}>
                        <Button
                            className={s.enterButton}
                            type="link"
                            onClick={() => navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH)}
                        >
                            Войти
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
};

export default observer(RegistrationForm);
