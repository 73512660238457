import {FunctionComponent, useEffect, useState} from 'react';

import {EyeInvisibleOutlined, EyeOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input, Layout, Space, Typography} from 'antd';
import {AxiosError} from 'axios';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {EHTTPStatus, EPageTitle} from '../../../Core/Enums';
import {useAuth} from '../../../Services/Auth';

interface IAuthData {
    email: string;
    password: string;
}

const AuthForm: FunctionComponent = () => {
    const navigate = useNavigate();
    const {login} = useAuth();
    const [form] = Form.useForm();
    const [authErrors, setAuthErrors] = useState<string[]>([]);

    useEffect(() => {
        document.title = EPageTitle.LOGIN;
    }, []);

    const handleSignIn = async (authData: IAuthData) => {
        const payload = {email: authData.email, password: authData.password};
        try {
            await login(payload);
            navigate(ROUTES.APP.PATH);
        } catch (e) {
            const err = e as AxiosError;

            if (err.response?.status === EHTTPStatus.NOT_FOUND) setAuthErrors(['Пользователь не найден']);

            if (err.response?.status === EHTTPStatus.UNAUTHORIZED) setAuthErrors(['Неверная почта или пароль']);
        }
    };

    const onFinish = async (authData: IAuthData) => {
        await form.validateFields();
        await handleSignIn(authData);
    };

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} не может быть пустым',
    };

    return (
        <Layout className={s.authPage}>
            <Form
                className={s.authForm}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={onFinish}
                onValuesChange={() => {
                    setAuthErrors([]);
                }}
            >
                <Typography.Title className={s.title}>AI Rabbit</Typography.Title>
                <Form.Item className={s.formItem} name="email" rules={[{required: true}]}>
                    <Input className={s.antInput} prefix={<UserOutlined className={s.icon} />} placeholder="E-MAIL" />
                </Form.Item>
                <Form.Item className={s.formItem} name="password" rules={[{required: true}]}>
                    <Input.Password
                        className={s.antInput}
                        prefix={<LockOutlined className={s.icon} />}
                        placeholder="ПАРОЛЬ"
                        iconRender={(visible) =>
                            visible ? (
                                <EyeOutlined style={{color: '#ffffff'}} />
                            ) : (
                                <EyeInvisibleOutlined style={{color: '#ffffff'}} />
                            )
                        }
                    />
                </Form.Item>

                <div className={s.errorList}>
                    <Form.ErrorList errors={authErrors} />
                </div>

                <Space className={s.space} direction="horizontal" align="center">
                    <Button
                        type="link"
                        className={s.textButton}
                        onClick={() => navigate(ROUTES.UNAUTHORIZED.REGISTRATION.PATH)}
                    >
                        Регистрация
                    </Button>
                    <Button
                        type="link"
                        className={s.textButton}
                        onClick={() => navigate(ROUTES.UNAUTHORIZED.PASSWORD_RESTORE.PATH)}
                    >
                        Забыли пароль?
                    </Button>
                </Space>

                <Form.Item className={s.formItem}>
                    <Button className={s.enterButton} type="primary" htmlType="submit">
                        ВОЙТИ
                    </Button>
                </Form.Item>
            </Form>
        </Layout>
    );
};

export default observer(AuthForm);
