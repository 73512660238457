import {FunctionComponent} from 'react';

import {EProfileTabs} from 'Core/Enums';
import {observer} from 'mobx-react';

import ProfileBalances from '../ProfileBalances';
import ProfilePackages from '../ProfilePackages';
import ProfileSettings from '../ProfileSettings';
import s from './styles.module.scss';

interface HeaderProfileProps {
    activeTab: EProfileTabs;
}

const ProfileContent: FunctionComponent<HeaderProfileProps> = ({activeTab}) => {
    const renderTabContent = () => {
        switch (activeTab) {
            case EProfileTabs.settings:
                return <ProfileSettings />;
            case EProfileTabs.tariffs:
                return <ProfilePackages />;
            case EProfileTabs.balances:
                return <ProfileBalances />;
        }
    };

    return (
        <div className={s.container}>
            <div className={s.tabContent}>{renderTabContent()}</div>
        </div>
    );
};

export default observer(ProfileContent);
