import {FunctionComponent, useEffect} from 'react';

import {observer} from 'mobx-react';

import {EPageTitle} from '../../../Core/Enums';
import {useAuth} from '../../../Services/Auth';
import Profile from '../../Components/Profile';

const ProfilePage: FunctionComponent = () => {
    const {getAvailablePacks} = useAuth();
    useEffect(() => {
        document.title = EPageTitle.PROFILE;
        void getAvailablePacks();
    }, []);

    return <Profile />;
};

export default observer(ProfilePage);
