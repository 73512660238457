import {FunctionComponent, useEffect, useState} from 'react';

import {Button, Form, Input, Layout, Typography} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {observer} from 'mobx-react';
import {useNavigate, useParams} from 'react-router-dom';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {EPageTitle} from '../../../Core/Enums';
import {useAuth} from '../../../Services/Auth';

const PasswordResetForm: FunctionComponent = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {resetPassword} = useAuth();
    const [passwordValue, setPasswordValue] = useState('');
    const [passwordConfirmValue, setPasswordConfirmValue] = useState('');
    const {token} = useParams();

    useEffect(() => {
        document.title = EPageTitle.PASSWORD_RESET;
    }, []);

    const handleOnClickSubmit = async (): Promise<void> => {
        const payload = {
            password: passwordValue,
            confirmed_password: passwordConfirmValue,
            confirmation_token: token,
        };
        void resetPassword(payload);
        navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH);
    };

    return (
        <Layout className={s.authPage}>
            <Content className={s.authContent}>
                <Form form={form} className={s.authForm} layout="vertical" name="nest-messages">
                    <Typography.Title className={s.title}>AI Rabbit</Typography.Title>
                    <Form.Item className={s.passItem} label="Пароль" name="password" rules={[{required: true}]}>
                        <Input.Password value={passwordValue} onChange={(e) => setPasswordValue(e.target.value)} />
                    </Form.Item>
                    {passwordValue ? (
                        <Form.Item
                            className={s.confirmPassItem}
                            label="Подтверждение пароля"
                            name="password-confirm"
                            rules={[{required: true}]}
                        >
                            <Input.Password
                                value={passwordConfirmValue}
                                onChange={(e) => setPasswordConfirmValue(e.target.value)}
                            />
                        </Form.Item>
                    ) : null}
                    <Form.Item className={s.changePassItem}>
                        <Button
                            className={s.changeButton}
                            type="primary"
                            htmlType="submit"
                            onClick={handleOnClickSubmit}
                        >
                            Сменить
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
};

export default observer(PasswordResetForm);
