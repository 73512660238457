import {FunctionComponent} from 'react';

import {ConfigProvider} from 'antd';
import AppRouter from 'Core/AppRouter';
import {observer} from 'mobx-react';

const App: FunctionComponent = () => {
    return (
        <ConfigProvider
            theme={{
                token: {colorPrimary: '#131337'},
                components: {
                    Select: {
                        optionSelectedBg: '#7c7ca060',
                    },
                    Button: {
                        linkHoverBg: '#ffffff',
                    },
                },
            }}
        >
            <AppRouter />
        </ConfigProvider>
    );
};

export default observer(App);
