import React, {FC} from 'react';

import {DeleteOutlined} from '@ant-design/icons';
import {Flex, Typography} from 'antd';
import ROUTES from 'Core/Const/Routes';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import {usePrompts} from 'Services/Prompts';
import useStores from 'Stores';

import s from './styles.module.scss';

interface IProps {
    chat: any;
}

const MenuChat: FC<IProps> = ({chat}: IProps) => {
    const {setCurrentHistoryChatId, setCurrentHistoryChatTitle} = useStores().historyStore;
    const {modifier, setIsSidebarOpen} = useStores().searchStore;
    const {getHistoryChat, deleteChatFromHistory, getHistory} = usePrompts();
    const navigate = useNavigate();

    return (
        <Flex className={s.chatMenuItemWrapper} align="center" justify="space-between">
            <Typography.Text
                className={s.chatMenuItemTitle}
                onClick={async () => {
                    setCurrentHistoryChatId(chat.id);
                    setCurrentHistoryChatTitle(chat.title);
                    await getHistoryChat(chat.id);
                    navigate(ROUTES.APP.PATH);
                    if (modifier === 'Mobile') {
                        setIsSidebarOpen(false);
                    }
                }}
            >
                {chat.title}
            </Typography.Text>
            <DeleteOutlined
                className={s.popoverIcon}
                onClick={async () => {
                    await deleteChatFromHistory(chat.id);
                    await getHistory();
                }}
            />
        </Flex>
    );
};

export default observer(MenuChat);
