import React, {useState} from 'react';

import {CopyOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import s from './styles.module.scss';

import 'highlight.js/styles/lightfair.css';

const MessageContent: React.FC<{content: string}> = ({content}) => {
    const [copied, setCopied] = useState<string | null>(null);

    const handleCopy = (code: string) => {
        void navigator.clipboard.writeText(code);
        setCopied(code);
        setTimeout(() => setCopied(null), 2000);
    };

    return (
        <ReactMarkdown
            className={s.answer}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw, rehypeHighlight]}
            components={{
                pre({node, children}) {
                    // @ts-expect-error
                    const codeText = (node.children[0] as any)?.props?.children[0] || '';
                    return (
                        <div className={s.codeBlock}>
                            <pre>{children}</pre>
                            <Button
                                type="link"
                                size="large"
                                icon={<CopyOutlined className={s.copyIcon} />}
                                className={s.copyButton}
                                onClick={() => handleCopy(codeText)}
                            >
                                {copied === codeText ? 'Скопировано!' : 'Копировать'}
                            </Button>
                        </div>
                    );
                },
            }}
        >
            {content.replace(/^"|"$/g, '').replace(/\\n/g, '\n')}
        </ReactMarkdown>
    );
};

export default MessageContent;
