import {FunctionComponent} from 'react';

import {Button, Form, Input, Flex, Typography} from 'antd';
import {observer} from 'mobx-react';

import s from './styles.module.scss';
import {useAuth} from '../../../Services/Auth';

const {Title} = Typography;

const ProfileSettingsChangePassword: FunctionComponent = () => {
    const [form] = Form.useForm();
    const {changePassword} = useAuth();

    const handleSubmit = async () => {
        const passwordField = form.getFieldsValue(['password']);

        if (passwordField.password) {
            await changePassword(passwordField.password);
            form.resetFields();
        }
    };

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} не может быть пустым',
    };

    return (
        <Flex vertical={true} className={s.wrapper}>
            <Title className={s.title} level={4}>
                Смена пароля
            </Title>
            <Form layout="vertical" form={form} validateMessages={validateMessages}>
                <Form.Item className={s.formItem} label="Новый пароль" name="password" rules={[{required: true}]}>
                    <Input.Password className={s.antInput} />
                </Form.Item>
                <Form.Item
                    className={s.formItem}
                    label="Подтверждение пароля"
                    name="confirm"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                        },
                        ({getFieldValue}) => ({
                            async validator(_, value) {
                                if (!value || getFieldValue('password') === value) return await Promise.resolve();
                                return await Promise.reject(new Error('Пароли не совпадают'));
                            },
                        }),
                    ]}
                >
                    <Input.Password className={s.antInput} />
                </Form.Item>
                <Button className={s.button} type="primary" htmlType="submit" onClick={handleSubmit}>
                    Сохранить
                </Button>
            </Form>
        </Flex>
    );
};

export default observer(ProfileSettingsChangePassword);
