import {headers} from './axios';
import memoizedRefreshToken from './refresh';
import {OPENAI_CHAT_ENDPOINT, YANDEX_CHAT_ENDPOINT} from '../Core/Const/Endpoints';
import {BACKEND_SERVICE_URL} from '../Core/Const/Env';
import {AnswerMode} from '../Core/Enums';
import {getAccessToken, getAccessTokenHeader, isAccessTokenExpired, isTokensExist} from '../Services/LocalStorage';

export default class Streaming {
    public sendStreamingMessage = async (
        messages: Array<{role: string; content: string}>,
        model: string
    ): Promise<any> => {
        let streaming = true;

        if (model === AnswerMode.O1_MINI || model === AnswerMode.O1_PREVIEW) {
            streaming = false;
        }

        return await fetch(`${BACKEND_SERVICE_URL}${OPENAI_CHAT_ENDPOINT}?model_name=${model}&streaming=${streaming}`, {
            method: 'POST',
            headers: await this.extendedHeaders(),
            body: JSON.stringify({model, messages}),
        });
    };

    public sendStreamingMessageYandex = async (
        messages: Array<{role: string; text: string}>,
        model: string
    ): Promise<any> => {
        return await fetch(`${BACKEND_SERVICE_URL}${YANDEX_CHAT_ENDPOINT}?model_name=${model}&stream=true`, {
            method: 'POST',
            headers: await this.extendedHeaders(),
            body: JSON.stringify({model, messages}),
        });
    };

    public streamingGenerator = async function* (streamingResponse: Response) {
        const decoder = new TextDecoder();

        // Set up a new ReadableStream to read the response body
        // @ts-expect-error
        const reader = streamingResponse.body.getReader();

        // Read the response stream as chunks and append them to the chat log
        while (true) {
            const {done, value} = await reader.read();
            if (done) break;

            yield decoder.decode(value);
        }
    };

    private readonly extendedHeaders = async (): Promise<any> => {
        const extendedHeaders: any = headers;

        if (isTokensExist()) {
            if (isAccessTokenExpired()) {
                await memoizedRefreshToken();
            }

            if (getAccessToken()) {
                extendedHeaders.Authorization = getAccessTokenHeader();
            }
        }

        return extendedHeaders;
    };
}
