import React, {FunctionComponent} from 'react';

import {MailOutlined} from '@ant-design/icons';
import {Layout, Row, Space, Typography} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {observer} from 'mobx-react';

import useStores from '../../../Stores';

const RegistrationConfirm: FunctionComponent = () => {
    const {email} = useStores().profileStore;

    return (
        <Layout style={{height: '100vh'}}>
            <Content style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Space direction="vertical">
                    <Row style={{width: '100%', paddingBottom: 30}} justify="center">
                        <MailOutlined style={{fontSize: 120, color: '#6C61ed'}} />
                    </Row>
                    <Row justify="center">
                        <Typography.Title>Пожалуйста, подтвердите регистрацию</Typography.Title>
                    </Row>
                    <Row justify="center">
                        <Typography.Text>Ссылка с подверждением отправлена на email {email}</Typography.Text>
                    </Row>
                    <Row style={{paddingTop: 30}} justify="center">
                        <Typography.Title className="form-header__title">AI Rabbit</Typography.Title>
                    </Row>
                </Space>
            </Content>
        </Layout>
    );
};

export default observer(RegistrationConfirm);
