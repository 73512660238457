/**
 * Adaptive Layout Breakpoints
 * @enum {string}
 */
export const Breakpoints = {
    Mobile: '499px',
    LargeMobile: '779px',
    Tablet: '1039px',
    Desktop: '1459px',
};

/**
 * Resize Breakpoints in numerical form
 * @enum {number}
 */
export const ResizeBreakpoints = {
    Mobile: 500,
    LargeMobile: 780,
    Tablet: 1040,
    Desktop: 1460,
};

export const getScreenModifier = (width: number): 'Mobile' | 'LargeMobile' | 'Tablet' | null => {
    if (width < ResizeBreakpoints.Mobile) {
        return 'Mobile';
    }
    if (width < ResizeBreakpoints.LargeMobile) {
        return 'LargeMobile';
    }
    if (width < ResizeBreakpoints.Tablet) {
        return 'Tablet';
    }
    return null;
};
