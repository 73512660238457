import {FunctionComponent, useEffect} from 'react';

import {observer} from 'mobx-react';
import HeaderChats from 'UI/Components/HeaderChats';

import {EPageTitle} from '../../../Core/Enums';
import {useAuth} from '../../../Services/Auth';
// import useStores from '../../Stores';
import MessageContainer from '../../Components/MessageContainer';
import MessageInput from '../../Components/MessageInput';
import PageWrapper from '../../Components/PageWrapper';
// import PromptInput from '../Components/PromptInput';

const SearchPage: FunctionComponent = () => {
    const {getAiModels} = useAuth();
    // const {prompt} = useStores().promptStore;
    // const {hasSubscription} = useStores().profileStore;
    // const [subscriptionStatusValue, setSubscriptionStatusValue] = useState(false);

    useEffect(() => {
        document.title = EPageTitle.CHAT;
        // setSubscriptionStatusValue(true);
        void getAiModels();
    }, []);

    // const renderInputFooter = (): ReactElement => {
    //     if (subscriptionStatusValue) {
    //         if (hasSubscription && !prompt) return <MessageInput />;
    //         else if (prompt) return <PromptInput />;
    //     }
    //
    //     return <></>;
    // };

    return (
        <PageWrapper header={<HeaderChats />}>
            <MessageContainer />
            <MessageInput />
        </PageWrapper>
    );
};

export default observer(SearchPage);
