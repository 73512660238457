import {FunctionComponent, useEffect} from 'react';

import {observer} from 'mobx-react';

import {EPageTitle} from '../../../Core/Enums';
import PageWrapper from '../../Components/PageWrapper';
import PromptInput from '../../Components/PromptInput';
import Prompts from '../../Components/Prompts';

const PromptPage: FunctionComponent = () => {
    useEffect(() => {
        document.title = EPageTitle.PROMPTS;
    }, []);

    return (
        <PageWrapper footer={<PromptInput />}>
            <Prompts />
        </PageWrapper>
    );
};

export default observer(PromptPage);
