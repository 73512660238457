import {action, makeObservable, observable} from 'mobx';

export default class HistoryStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    chats: any[] = [];

    @action
    setChats = (chats: any): void => {
        this.chats = chats;
    };

    @observable
    currentHistoryChatTitle: string | null = null;

    @action
    setCurrentHistoryChatTitle = (value: string | null) => {
        this.currentHistoryChatTitle = value;
    };

    @observable
    currentHistoryChatId: number | null = null;

    @action
    setCurrentHistoryChatId = (value: number | null) => {
        this.currentHistoryChatId = value;
    };

    @action
    deleteChat = (chatId: number): void => {
        this.chats = this.chats.filter((chat) => chat.id !== chatId);
    };
}
