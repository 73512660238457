import {FC} from 'react';

import {Col, Row, Typography} from 'antd';
import {observer} from 'mobx-react';
import useStores from 'Stores';

import MenuChat from '../MenuChat';
import s from './styles.module.scss';

// TODO: убрать закрывание сайдбара при открытии диалога
const MenuChats: FC = () => {
    const {chats} = useStores().historyStore;

    return (
        <Col className={s.recentlyChatsWrapper}>
            <Typography.Text strong className={s.recentlyText}>
                Недавно
            </Typography.Text>
            <Row className={s.recentlyChats}>
                <Col className={s.recentlyColumn}>
                    {chats.map((chat, i) => (
                        <MenuChat chat={chat} key={chat.id} />
                    ))}
                </Col>
            </Row>
        </Col>
    );
};

export default observer(MenuChats);
