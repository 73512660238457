import {
    PROMT_ENDPOINT,
    getPromtEndpoint,
    getChatEndpoint,
    CHAT_ENDPOINT,
    SAVED_CHAT_ENDPOINT,
    getSavedChatEndpoint,
} from '../../Core/Const/Endpoints';
import {authClient} from '../axios';
import {ICreatePromptPayload} from './types';

export default class AiDisruptApi {
    fetchPrompts = async (searchPhrase?: string): Promise<any> => {
        return await authClient.get(PROMT_ENDPOINT, {params: {search_phrase: searchPhrase}});
    };

    createPrompt = async (payload: ICreatePromptPayload): Promise<number> => {
        const response = await authClient.post(PROMT_ENDPOINT, payload);
        return response.data.prompt_id;
    };

    updatePrompt = async (promptId: number, payload: ICreatePromptPayload): Promise<void> => {
        return await authClient.put(getPromtEndpoint(promptId), payload);
    };

    fetchPrompt = async (id: number): Promise<any> => {
        return await authClient.get(getPromtEndpoint(id));
    };

    deletePrompt = async (id: number): Promise<any> => {
        return await authClient.delete(getPromtEndpoint(id));
    };

    saveChat = async (payload: any): Promise<any> => {
        const response = await authClient.post(CHAT_ENDPOINT, payload);
        return response.data;
    };

    updateChat = async (chatId: string, payload: any): Promise<any> => {
        const response = await authClient.put(getChatEndpoint(chatId), payload);
        return response.data;
    };

    saveHistoryChat = async (payload: any): Promise<any> => {
        const response = await authClient.post(SAVED_CHAT_ENDPOINT, payload);
        return response.data;
    };

    getHistory = async (searchPhrase?: string): Promise<any> => {
        const response = await authClient.get(SAVED_CHAT_ENDPOINT, {params: {search_phrase: searchPhrase}});
        return response.data;
    };

    getHistoryChat = async (id: number): Promise<any> => {
        const response = await authClient.get(getSavedChatEndpoint(id));
        return response.data;
    };

    updateHistoryChat = async (id: number, payload: any): Promise<any> => {
        await authClient.put(getSavedChatEndpoint(id), payload);
    };

    deleteChatFromHistory = async (id: number): Promise<any> => {
        await authClient.delete(getSavedChatEndpoint(id));
    };
}
