import axios from 'axios';
import {BACKEND_SERVICE_URL} from 'Core/Const/Env';
import {isAccessTokenExpired, getAccessTokenHeader, isTokensExist, getAccessToken} from 'Services/LocalStorage';

import memoizedRefreshToken from './refresh';

export const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
};

export const client = axios.create({
    baseURL: BACKEND_SERVICE_URL,
    headers,
});

export const authClient = axios.create({
    baseURL: client.defaults.baseURL,
    headers: client.defaults.headers,
});

authClient.interceptors.request.use(
    async (config) => {
        if (isTokensExist()) {
            if (isAccessTokenExpired()) {
                await memoizedRefreshToken();
            }

            if (getAccessToken()) {
                config.headers.Authorization = getAccessTokenHeader();
            }
        }

        return config;
    },
    async (error) => await Promise.reject(error)
);
