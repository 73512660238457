export enum ERole {
    USER = 'user',
    ASSISTANT = 'assistant',
    CONTEXT_END = 'end-of-context',
}

export enum EMessageType {
    TEXT = 'text',
    IMAGE = 'image',
}

export enum EAiMode {
    CHAT = 'chat',
    IMAGE_GENERATION = 'image_generation',
    CHAT_BARD_RU = 'chat_bard_ru',
    CHAT_BARD_EN = 'chat_bard_en',
}

export enum AnswerMode {
    O1_PREVIEW = 'o1-preview',
    O1_MINI = 'o1-mini',
    GPT_4 = 'gpt-4',
    GPT_4_PRO = 'gpt-4-1106-preview',
    GPT_4_O = 'gpt-4o',
    GPT_4_O_MINI = 'gpt-4o-mini',
    GPT_3_5 = 'gpt-3.5-turbo',
    DALLE_3 = 'dall-e-3',
    DALLE_2 = 'dall-e-2',
    YANDEX_GPT_LITE = 'yandexgpt-lite',
    YANDEX_GPT_PRO = 'yandexgpt',
    YANDEX_GPT_SUM = 'summarization',
    TEXT_TO_IMAGE = 'text-to-image',
}

export enum ELang {
    RU = 'ru',
    EN = 'en',
}

export enum EPageTitle {
    LOGIN = 'Вход',
    REGISTRATION = 'Регистрация',
    CHAT = 'Чат',
    PROMPTS = 'Шаблоны запросов',
    PROFILE = 'Профиль',
    PASSWORD_RESTORE = 'Востанновление пароля',
    PASSWORD_RESET = 'Обновление пароля',
}

export enum EHTTPStatus {
    NOT_FOUND = 404,
    UNAUTHORIZED = 401,
    SUCCESS = 200,
}

export enum EPromptDataItemName {
    TITLE = 'title',
    DESCRIPTION = 'description',
    TEMPLATE = 'template',
}

export enum EPromptFormLabels {
    TITLE = 'Название',
    DESCRIPTION = 'Описание',
    TEMPLATE = 'Текст запроса',
}

export enum EProfileTabs {
    settings,
    tariffs,
    balances,
}
