import {FunctionComponent} from 'react';

import {Space, Typography, Flex, Divider, Button} from 'antd';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import useStores from 'Stores';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {useAuth} from '../../../Services/Auth';

const {Text, Title} = Typography;

const ProfileSettingsInfo: FunctionComponent = () => {
    const {login, email} = useStores().profileStore;
    const navigate = useNavigate();
    const {logout} = useAuth();

    const handleLogout = () => {
        void logout();
        navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH);
    };

    return (
        <Flex vertical={true} className={s.wrapper}>
            <Title className={s.title} level={4}>
                Информация
            </Title>
            <Space className={s.text}>
                <Text>Логин: </Text>
                <Text>{login}</Text>
            </Space>
            <Space className={s.text}>
                <Text>Email: </Text>
                <Text>{email}</Text>
            </Space>
            <Divider className={s.divider} />
            <Button onClick={handleLogout} className={s.button}>
                Выход
            </Button>
        </Flex>
    );
};

export default observer(ProfileSettingsInfo);
